export const SuccessErrorConst = {

   loginAgain: 'Please login Again!',
   addedSuccess: 'Added Successfully!',
   updatedSuccess: 'Updated Successfully!',
   deleteSuccess: 'Deleted Successfully!',
   approveSuccess: 'Updated Successfully!',
   messageSuccess: 'Message sent successfully!',
   required: 'This field is required.',
   validEmail: 'Please enter valid email!.',
   noImageAdded: 'Please add atleast 1 image!.',
   validPhone: 'Please enter valid phone number',
   clientLoginSuccess: 'Login Successfully',
   blocked: 'You are blocked',
   deleted: 'Admin deleted you'

};
