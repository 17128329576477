export const GlobalVariable = {

    SITE_NAME: 'Urvi - The sustainable and reusable system for takeouts',
    PATTERNS: {
        email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    },
    tokenKey: 'vebkard_client_token_key',

    languageType : 1 ,
};

export const CONSTANT = {
    languages: [{name: 'English', id: 1}, {name: 'Spanish', id: 2}],

};
