import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotfoundComponent } from './notfound/notfound.component';
// guards
import { LayoutGuard } from './services/guards/layout.guard';

const routes: Routes = [
    
    {
        path: '',
        loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
    },  
    {
       path :'404', component : NotfoundComponent ,
    },
    {
        path: '**', redirectTo: '404'
    }
];

@NgModule({

    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
